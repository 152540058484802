import { CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowRight from "@material-ui/icons/ArrowForward";
import clsx from "clsx";
import { Services as sdkService } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import { Tune } from "../../icons";
import { MobileLogo, Refresh as SvgRefresh } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import FilterRequire from "../MobileFilterRequire/FilterRequire";
import useStyles from "./MobileUnitDiagnostics.style";
import ParamsList from "./ParamsList";

const MobileUnitDiagnostics = () => {
  const history = useHistory();
  const classes: any = useStyles();
  const { unitId: selectedUnitId, siteId: selectedSiteId } = useStoreState((state) => state.selections.mobileSelections);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getUnit = useStoreState((state) => state.units.getUnit);

  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const getUnitParamsAndStats = useStoreActions((a) => a.units.getUnitParamsAndStats);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale || 0);
  const userMeasurementUnits = useStoreState((s) => s.users.me.measurementUnits || 1);
  const enums = useStoreState((state) => state.serviceParamTypes);
  const setServiceParams = useStoreActions((s) => s.setServiceParams);
  const setServiceParamTypes = useStoreActions((s) => s.setServiceParamTypes);

  const timezone = allSites[selectedSiteId]?.timezone || "";
  const unit = allUnits[selectedUnitId];

  const [controlUnit, setControlUnit] = useState<any>(undefined);
  const [openParamsList, setOpenParamsList] = useState<boolean>(false);
  const [minTimestamp, setMinTimestamp] = useState<number>(new Date(new Date().setHours(0, 0, 0)).valueOf());
  const [maxTimestamp, setMaxTimestamp] = useState<number>(moment.now());
  const [timestamps, setTimestamps] = useState<any>([]);
  const [timestampsData, setTimestampsData] = useState<any>({});
  const [tableParams, setTableParams] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<number>(moment.now());
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [time, setTime] = useState<any>({
    start: moment(new Date(new Date().setHours(0, 0, 0))).valueOf(),
    end: moment.now()
  });
  const [unitName, setUnitName] = useState<string>("");

  const slidersRef: any = useRef([]);

  useEffect(() => {
    if (slidersRef.current.length) {
      slidersRef.current.forEach((slider: any) => {
        slider?.addEventListener(
          "touchstart",
          (e: any) => {
            const isThumb = e.target?.dataset.index;
            if (!isThumb) {
              e.stopPropagation();
            }
          },
          { capture: true }
        );
      });
    }
  }, [slidersRef.current.length]);

  useEffect(() => {
    if (_.isEmpty(serviceParams)) {
      Promise.all([sdkService.getServiceParams(), sdkService.getServiceParamTypes()])
        .then((resp: any) => {
          const [params, paramsTypes] = resp;
          setServiceParamTypes(paramsTypes);
          setServiceParams(params);
        });
    }
  }, []);

  useEffect(() => {
    if (!selectedUnitId) {
      unitName && setUnitName("");
      return;
    }
    let name: string = "";
    const unit = getUnit(selectedUnitId);
    if (unit) {
      name = unit.name;
      if (+unit?.type === 3) { // is service unit
        const controlUnitId: any = unit.controlUnit;
        const controlUnit = getUnit(controlUnitId);
        setControlUnit(controlUnit);
        const controlName = controlUnit?.name || t`Unassigned`;
        name = `${controlName} (${unit.address})`;
      }
      else {
        setControlUnit(undefined);
      }
    }
    setUnitName(name);
  }, [selectedUnitId]);

  useEffect(() => {
    if (!selectedUnitId || _.isEmpty(serviceParams)) {
      return;
    }
    fetchData(selectedUnitId, time.start, time.end, serviceParams);
  }, [selectedUnitId, time, serviceParams]);

  const getLastUpdate = async () => {
    const dateRange = {
      start: new Date(new Date().setHours(0, 0, 0)).valueOf(),
      end: moment.now()
    };
    setTime(dateRange);
    setMinTimestamp(new Date(new Date().setHours(0, 0, 0)).valueOf());
    setMaxTimestamp(moment.now());

  };

  const fetchData = (unitId: string, startTime: number, endTime: number, serviceParams: any) => {
    setIsLoading(true);
    getUnitParamsAndStats({
      unitId,
      startTime,
      endTime
    })
      .then((resp: any) => {
        const currentobj = resp?.current && resp?.current[0];
        prepareTableData(resp.ranges, currentobj, serviceParams);
        let tempTimestamps: any[] = [];
        const tempTimestampsData = resp.entries.reduce((obj: any, data: any) => {
          obj[data.timestamp] = data;
          tempTimestamps.push({ value: data.timestamp });
          return obj;

        }, {});
        setTimestampsData(tempTimestampsData);
        setTimestamps(tempTimestamps);
        setMinTimestamp(tempTimestamps[0]?.value);
        const lastItem = tempTimestamps[tempTimestamps.length - 1];
        setMaxTimestamp(lastItem?.value);
        setSelectedTime(lastItem?.value);
        setSliderValue(Math.floor(lastItem?.value / 60 / 1000));
      })
      .finally(() => setIsLoading(false));
  };

  const prepareTableData = async (data: any, currentObj: any, serviceParams: any) => {
    const tableRows: any = {};

    const unitSupportedParams = !data ? null : Object.keys(data);
    if (data && !_.isEmpty(serviceParams) && unitSupportedParams && currentObj) {

      unitSupportedParams && !_.isEmpty(unitSupportedParams) && unitSupportedParams.forEach((code: any) => {
        const servParam = serviceParams[code];
        if (!servParam) {
          return;
        }

        let {
          data_unit_of_measurement: measurementUnit = "",
          title = "",
          plotable,
          isCalculated
        } = servParam;

        if (title === "Room Temp" || title === "Leaving water main heating setpoint fixed" || title === "Leaving water main cooling setpoint fixed") {
          title = title + (unit?.heaterTempControl === 0 || controlUnit?.heaterTempControl === 0 ? " (LWT)" : " (RC)");
        }

        const isShowing = true;
        let measurementUnits: string = measurementUnit;

        if (temperatureScale === 2 && measurementUnit === "°C") {
          measurementUnits = "°F";
        }
        if (userMeasurementUnits === 2 && measurementUnit === "kg/cm2") {
          measurementUnits = "PSI";
        }
        if (measurementUnit === "MPa" && userMeasurementUnits === 2) {
          measurementUnits = "PSI";
        }

        const measuredParam: boolean = servParam
          ? !_.isUndefined(plotable)
            ? plotable
            : true
          : false;

        tableRows[code] = {
          code,
          isShowing,
          name: title,
          measurementUnits,
          plottable: measuredParam,
          isCalculated
        };
      });
    }
    setTableParams(tableRows);
  };

  const setPrevMinute = async () => {
    const currentTime = Object.keys(timestampsData).indexOf(String(selectedTime));
    if (currentTime === 0) {
      return;
    }
    setSliderValue(sliderValue - 1);
    setSelectedTime(timestamps[currentTime - 1]?.value);
  };
  const setNextMinute = async () => {
    const currentTime = Object.keys(timestampsData).indexOf(String(selectedTime));

    if (currentTime === timestamps.length - 1) {
      return;
    }
    setSliderValue(sliderValue + 1);
    setSelectedTime(timestamps[currentTime + 1]?.value);
  };
  const valueDateText = (value: number) => {
    const timeToShow = moment(value).tz(timezone).format(`${dateFormat} ${timeFormat}`);
    return `${timeToShow}`;
  };
  const valuetext = (value: number) => {
    const timeToShow = moment(value).tz(timezone).format(timeFormat);
    return `${timeToShow}`;
  };

  const changeSelectedTime = (_: React.ChangeEvent<{}>, value: any) => {
    setSelectedTime((Number(value) * 60 * 1000 + minTimestamp) || 0);
  };

  const valueLabelFormat = (mins: number) => {
    const timeToShow = moment(mins * 60 * 1000 + minTimestamp).tz(timezone).format(timeFormat);
    const date: any = moment(mins * 60 * 1000 + minTimestamp);

    if (!date?.isValid()) {
      return "No data";
    }
    return `${timeToShow}`;
  };

  return (
    <>
      <TopBar leftAction={() => history.push("/dashboard")}
        leftIconComponent={<MobileLogo />}
        applySiteTypeFiltering
        screenTitle="unitDiag"
      />
      {!selectedUnitId ? <FilterRequire type={t`unit`} /> : (

        <div className={classes.root}>
          <div className={classes.pageContent}>
            <div className={classes.header}>
              <Typography className={classes.screenTitle}>{t`Unit Diagnostics`}</Typography>
              <SvgRefresh className={classes.refreshStyle} onClick={getLastUpdate} />
            </div>
            <Grid container direction="row" alignItems="stretch" className={classes.sliderContainer}>
              <IconButton
                disableRipple
                onClick={setPrevMinute}
                className={clsx(classes.iconBtnStyle, { [classes.hidden]: minTimestamp === selectedTime })}
              >
                <ArrowBack />
              </IconButton>
              <Grid item className={classes.slider}>
                <Slider
                  ref={(el) => slidersRef.current[0] = el}
                  classes={{
                    root: classes.sliderRoot,
                    valueLabel: classes.sliderValueLabel
                  }}
                  onChange={(_, newVal) => setSliderValue(typeof newVal === "number" ? newVal : 1)}
                  onChangeCommitted={changeSelectedTime}
                  min={0}
                  max={maxTimestamp && minTimestamp ? Math.floor((maxTimestamp - minTimestamp) / 60 / 1000) : 1}
                  value={sliderValue}
                  valueLabelDisplay="on"
                  valueLabelFormat={valueLabelFormat}
                />
              </Grid>
              <IconButton
                disableRipple
                onClick={setNextMinute}
                className={clsx(classes.iconBtnStyle, { [classes.hidden]: maxTimestamp === selectedTime })}
              >
                <ArrowRight />
              </IconButton>
            </Grid>
            <Grid className={classes.block}>
              <Typography className={classes.sliderTime}>{valueDateText(time.start || minTimestamp)}</Typography>
              <Typography className={classes.sliderTime}>{valueDateText(time.end || maxTimestamp)}</Typography>
            </Grid>
            <div className={classes.paramsTable}>
              {isLoading ?
                <div className={classes.loaderContainer}>
                  <CircularProgress style={{ alignSelf: "center" }} />
                </div>
                :
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell
                          align={"left"}
                          className={clsx(classes.bold, classes.nameCell)}
                        >
                          <div className={classes.titleAndIcon}>
                            {t`Parameters`}
                            <IconButton
                              disableRipple
                              onClick={() => setOpenParamsList(true)}
                              className={classes.tuneIcon}
                            >
                              <Tune />
                            </IconButton>
                          </div>
                        </TableCell>
                        <TableCell
                          className={clsx(classes.bold, classes.tableCell, classes.unitNameCell)}
                          align={"left"}
                        >
                          <span className={classes.ellipsis}>
                            {unitName}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(tableParams).length === 0 && <Typography className={classes.noDataMsg}>{t`Data is not available for this period`}</Typography>}
                      {_.orderBy(Object.values(tableParams), ["plottable", "isCalculated", "name"], ["desc", "desc", "asc"])?.map((row: any, index: number) => {
                        const data = timestampsData[selectedTime];
                        const {
                          isShowing,
                          name,
                          measurementUnits,
                          code
                        } = row;

                        if(!data) {
                          return;
                        }

                        const value = data[code];

                        const servParam = serviceParams[code];
                        if (!servParam || !isShowing) {
                          return;
                        }

                        const {
                          enum: enumName = null
                        } = servParam;

                        let enumVal: any = null;
                        if (enumName) {
                          enumVal = enums[enumName][value];
                        }

                        let displayValue = enumVal ? enumVal : value;
                        if (typeof displayValue === "object") {
                          displayValue = "Invalid Value";
                        }

                        if (!isShowing) {
                          return null;
                        }

                        return (
                          <TableRow hover className={classes.tableRow} role="checkbox" tabIndex={-1} key={`param-row-${index}`}>
                            <TableCell
                              className={
                                clsx(classes.tableCell, classes.nameCell)}
                            >
                              {name}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                            >
                              {displayValue}{` `}{measurementUnits}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>}
            </div>
          </div>
        </div>
      )}
      {openParamsList && <ParamsList
        tableParams={tableParams}
        setTableParams={setTableParams}
        setOpenParamsList={setOpenParamsList}
      />}
    </>
  );
};

export default MobileUnitDiagnostics;
