import { useEffect, useRef, useState } from 'react';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const useCoolPoll = ({
  enabled = true,
  sendInitalPolling = false,
  frequency = MINUTE,            // Default 60 seconds
  doublePeriod = 5 * MINUTE,    // Default 5 minutes
  stopAfter = HOUR,             // Default 60 minutes
  inactivityThreshold = MINUTE, // Default 1 minute
  onPoll = () => {}
}) => {
  const [isActive, setIsActive] = useState(false);
  const lastActivityTimeRef = useRef(Date.now());
  const timeoutRef = useRef(null);
  const activityCheckRef = useRef(null);
  const startTimeRef = useRef(null);

  // Unified activity handling - requires both visibility and recent interaction
  const checkActivity = () => {
    const isVisible = !document.hidden;
    const timeSinceLastActivity = Date.now() - lastActivityTimeRef.current;
    const isWithinThreshold = timeSinceLastActivity < inactivityThreshold;
    
    const newActiveState = enabled && isVisible && isWithinThreshold;
    setIsActive(newActiveState);
    return newActiveState;
  };

  // Handle any user interaction
  const handleActivity = () => {
    if (!document.hidden) {  // Only update if page is visible
      lastActivityTimeRef.current = Date.now();
      checkActivity();
    }
  };

  // Cleanup function to remove all event listeners and timers
  const cleanup = () => {
    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.removeEventListener(event, handleActivity);
    });
    document.removeEventListener('visibilitychange', checkActivity);
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (activityCheckRef.current) {
      clearInterval(activityCheckRef.current);
    }
  };

  useEffect(() => {
    if (!enabled) {
      setIsActive(false);
      cleanup();
      return;
    }

    // Initialize
    startTimeRef.current = Date.now();
    lastActivityTimeRef.current = Date.now();

    // Set up event listeners
    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });
    document.addEventListener('visibilitychange', checkActivity);
    
    // Regular activity checks
    activityCheckRef.current = setInterval(checkActivity, 10000);

    // Initial check
    checkActivity();

    return cleanup;
  }, [enabled]); // Added enabled to dependencies

  useEffect(() => {
    if (!isActive) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      return;
    }

    const getCurrentInterval = () => {
      const timeSinceLastActivity = Date.now() - lastActivityTimeRef.current;
      const numberOfPeriods = Math.floor(timeSinceLastActivity / doublePeriod);
      return Math.min(frequency * Math.pow(2, numberOfPeriods), frequency * 8);
    };

    const scheduleNextPoll = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Check if we should stop polling
      if (Date.now() - startTimeRef.current >= stopAfter) {
        return;
      }

      const currentInterval = getCurrentInterval();
      
      timeoutRef.current = setTimeout(() => {
        if (checkActivity()) {
          onPoll();
          scheduleNextPoll();
        }
      }, currentInterval);
    };

    // Start polling
    if(sendInitalPolling) {
      onPoll();
    }

    scheduleNextPoll();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isActive, frequency, doublePeriod, stopAfter, onPoll]);

  return {
    isActive,
    isPolling: isActive && (Date.now() - startTimeRef.current < stopAfter),
    timeSinceLastActivity: Date.now() - lastActivityTimeRef.current
  };
};

export default useCoolPoll;