import { Services as SdkServices, xhr, xhr as SdkXhr } from "coolremote-sdk";
import queryString from "query-string";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AutomationLogic from "../AutomationLogic/AutomationLogic";
import Dashboard from "../Dashboard/Dashboard";
import DashboardUnit from "../Dashboard/DashboardUnit";
import Impersonate from "../Impersonate/Impersonate";
import Login from "../Login/Login";
import TenantReports from "../PowerDistribution/reports";
import ReportsScheduler from "../PowerDistribution/ReportsScheduler";
import PowerDistribution from "../PowerDistribution/Tenants";
import Settings from "../Settings/Settings";
import SiteManagement from "../SiteManagement/SiteManagement";
import SmartRules from "../SmartRules/SmartRules";
import TrapsList from "../Traps/TrapsList";
import UnitsEdits from "../UnitsEdit/UnitsEdit";
import UnitStatistics from "../UnitStatistics/UnitStatistics";
import UserList from "../UserManagement/UserList";

// Non 'JS Styles' CSS imports.
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import "rc-slider/assets/index.css";
import "react-flags-select/css/react-flags-select.css";
import { StaticContext } from "react-router";
import "react-table/react-table.css";
import { ErrorStoreMessage, UserIdentifierChangeRequired } from "../../components";
import { Loader } from "../../components/Loader";
import Loading from "../../components/Loading/Loading";
import { AboutUs } from "../../MobileScreens/AboutUs";
import { AddEditSite } from "../../MobileScreens/AddEditSite";
import AddEditSystem from "../../MobileScreens/AddEditSystem/AddEditSystem";
import EditDetectedSystems from "../../MobileScreens/EditDetectedSystems/EditDetectedSystems";
import MobileAlerts from "../../MobileScreens/MobileAlerts/Alerts";
import MobileControl from "../../MobileScreens/MobileControl/Control";
import MobileDashboard from "../../MobileScreens/MobileDashboard/Dashboard";
import GroupAddEdit from "../../MobileScreens/MobileGroupAddEdit/GroupAddEdit";
import GroupMgmt from "../../MobileScreens/MobileGroupMgmt/GroupMgmt";
import MobileForgotPassword from "../../MobileScreens/MobileLogin/ForgotPassword";
import MobileLogin from "../../MobileScreens/MobileLogin/Login";
import MobileInviteAccept from "../../MobileScreens/MobileLogin/MobileInviteAccept";
import MobileResetPassword from "../../MobileScreens/MobileLogin/ResetPassword";
import MobileResetPasswordWithToken from "../../MobileScreens/MobileLogin/ResetPasswordWithToken";
import MobileResetSuccess from "../../MobileScreens/MobileLogin/ResetSuccess";
import MobileSchedulesList from "../../MobileScreens/MobileScheduleList/ScheduleList";
import MobileSiteManagement from "../../MobileScreens/MobileSiteManagement/MobileSiteManagement";
import MobileUnitControlRestrict from "../../MobileScreens/MobileUnitControlRestrict/UnitManagement";
import MobileUnitDiagnostics from "../../MobileScreens/MobileUnitDiagnostics/MobileUnitDiagnostics";
import MobileUnitManagement from "../../MobileScreens/MobileUnitManagement/UnitManagement";
import MobileUnitStatistics from "../../MobileScreens/MobileUnitStatistics/MobileUnitStatistics";
import MobileWRCUnitSettings from "../../MobileScreens/MobileWRCUnitSettings/UnitManagement";
import { ProfileScreen } from "../../MobileScreens/Profile";
import RegisterNewDevice from "../../MobileScreens/RegisterNewDevice/RegisterNewDevice";
import RegisterNewDeviceQR from "../../MobileScreens/RegisterNewDevice/RegisterNewDeviceQR";
import SystemDetection from "../../MobileScreens/SystemDetection/SystemDetection";
import MobileUserMgmt from "../../MobileScreens/UserManagement/UserList";
import Alerts from "../Alerts/Alerts";
import AnomalyTemplatesList from "../AnomalyTemplatesList/AnomalyTemplatesList";
import BacnetDeviceList from "../Bacnet/BacnetDeviceList";
import CommissioningList from "../Commissioning/CommissioningList";
import CommissioningLogs from "../CommissioningLogs/CommissioningLogs";
import Control from "../Control/Control";
import FloorPlan from "../FloorPlan/FloorPlan";
import Groups from "../Groups/Groups";
import HeatMap from "../HeatMap/HeatMap";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import ResetPasswordWithToken from "../Login/ResetPasswordWithToken";
import ResetSuccess from "../Login/ResetSuccess";
import AutomaticChangeover from "../OperationAutomate/AutomaticChangeover";
import PPDSettings from "../PowerDistribution/PPDSettings";
import Setback from "../Setback/Setback";
import AlertSettings2 from "../Settings/AlertSettings2";
import Reports from "../Settings/Reports";
import UnitSetting from "../Settings/UnitSetting";
import SiteReportsScreen from "../SiteReports/SiteReportsScreen";
import SystemDiagnostics from "../SystemDiagnostics/SystemDiagnostics";
import AcceptInvite from "../UserManagement/AcceptInvite/AcceptInvite";
import _ from "lodash";
import EstimatedPowerUsage from "../PowerDistribution/EstimatedPowerUsage";
import AuditsPage from "../Audits/AuditsPage";
import PrivateRoute from "./components/PrivateRoute";
import MobileAudits from "../../MobileScreens/MobileAudits";
import useChurnZero from "@hooks/useChurnZero";
import BacnetDetection from "../Bacnet/BacnetDetection";
import BacNetMapping from "../BacNetMapping";
import SigmaReports from "../SigmaReports";

const matchedPathsWithParents: any = {
  "/commissioning-prodecuders": "operationAutomate",
  "/commissioning-logs": "operationAutomate",
  "/automatic/changeover": "operationAutomate",
  "/automation-logic": "operationAutomate",
  "/anomalies-settings": "operationAutomate",
  "/settings/alerts": "operationAutomate",
  "/smart-rules": "operationAutomate",
  "/unit-stats": "diagnostics",
  "/system-diagnostics": "diagnostics",
  "/unit-usage": "diagnostics",
  "/unit-statistics": "diagnostics",
  "/advanced-unit-search": "diagnostics",
  "/advanced-unit-search-logs": "diagnostics",
  "/audits": "diagnostics",
  "/bacnet-devices": "bacnets",
  "/bacnet-device-objects": "bacnets",
  "/bacnet-device-stats": "bacnets",
  "/settings/unit": "settings",
  "/settings/restricts": "settings",
  "/settings/groups": "settings",
  "/user": "users",
  "/tenantReports": "powerDistribution",
  "/estimatedPowerUsage": "powerDistribution",
  "/PowerDistribution": "powerDistribution",
  "/scheduler": "powerDistribution",
  "/site-reports-live": "reports",
  "/site-reports-scheduler": "reports",
  "/sigma-reports": "reports"
};


const isInviteURL = window.location.pathname.includes("invite");


export default function Routes() {
  const getUserTree = useStoreActions((action) => action.getUserTree);
  const getMe = useStoreActions((action) => action.users.getMe);
  const getUserTreeMobile = useStoreActions((action) => action.getUserTreeMobile);
  const setLoggedIn = useStoreActions((action) => action.setLoggedIn);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const isLoaderShowing = useStoreState((s) => s.loader.isLoading);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);
  const messageObj = useStoreState((s) => s.errorMessage.message);
  const isUsernameNotEmail = useStoreState((s) => s.isUsernameNotEmail);
  const setDropDownNavigation = useStoreActions((a) => a.setDropDownNavigation);
  const themeFile = useStoreState((s) => s.theme);
  const prepareTheme = useStoreActions((a) => a.prepareTheme);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const enableErrorPage = useStoreState((s) => s.enableErrorPage);
  const setTypes = useStoreActions((actions) => actions.setTypes);
  const configFile = useStoreState((s) => s.config);
  const me = useStoreState((s) => s.users.me);

  const { selections } = useStoreState((s) => s.selections);
  const mobileSelections = useStoreState((s) => s.selections.mobileSelections);
  const { customerId } = selections;
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const displayFlags = (mobileSelections?.customerId ? displayFlagsMap[mobileSelections?.customerId] : displayFlagsMap[customerId || "all"]) || {};

  const { enableBacnet } = me;

  const { initChurnZero } = useChurnZero();

  useEffect(() => {
    return initChurnZero();
  }, []);

  useEffect(() => {
    const tabTitle = configFile?.tabTitle;
    document.title = tabTitle || 'CoolProfessional';
  }, [configFile]);

  const initialize = async (showErrorMessages: boolean = true) => {
    const token = localStorage.getItem("token");
    if (isLoggedIn && token) {
      SdkXhr.setToken(token);
      if (isInviteURL) {
        await getMe().catch(() => {
          setLoggedIn(false);
        })
        return finishLoader();
      }
      if (isMobile) {
        await getUserTreeMobile(showErrorMessages);
        finishLoader();
      }
      else {
        try {
          getUserTree(showErrorMessages);
        } catch (error) {
          setLoggedIn(false);
          finishLoader();
        }
      }

    } else {
      setLoggedIn(false);
      finishLoader();
    }
  };

  useEffect(() => {
    if (!themeFile) {
      prepareTheme();
    }
  }, [themeFile]);

  useEffect(() => {
    if (window.location.pathname.includes("commissioning")) { setDropDownNavigation("commissioning"); }
    if (isInviteURL) {
      SdkServices.getTypes()
        .then((res: any) => {
          setTypes(res);
        });
    }
  }, []);

  useEffect(() => {
    let interval: any = null;
    if (isLoggedIn) {
      initialize();
      interval = setInterval(() => {
        initialize(false);
      }, 600000);
    } else {
      return () => clearInterval(interval);
    }

  }, [isLoggedIn]);

  useEffect(() => {
    if (isMobile || !isLoggedIn || !isInitialized) {
      return;
    }

    let pathname = window.location.pathname;

    if (pathname.includes("unit-stats")) {
      pathname = "/unit-stats";
    } else if (pathname.includes("system-diagnostics")) {
      pathname = "/system-diagnostics";
    } else if (pathname.includes("unit-statistics")) {
      pathname = "/unit-statistics";
    }

    const parent = matchedPathsWithParents[pathname];
    parent && setDropDownNavigation(parent);
  }, [isLoggedIn, isInitialized]);

  const unitUpdateStatusUpdate = (id: string) => {
    setUnitUpdateStatus({ status: id ?? "" });
  };

  if (!themeFile) {
    return <></>;
  }

  if (isLoggedIn && !isInviteURL && !isInitialized) {
    return <span />
  }

  const theme = createTheme(themeFile);

  if (isMobile) {
    return <MuiThemeProvider theme={theme}>
      <div className="AppMobile">
        <Loader showLoader={isLoaderShowing} />
        <Router>
          <Switch>
            <Route exact={true} path="/profile" component={ProfileScreen} />
            <Route exact={true} path="/forgot-password/" component={MobileForgotPassword} />
            <Route exact={true} path="/reset-password" component={MobileResetPassword} />
            <Route exact={true} path="/reset-password/:token" component={MobileResetPasswordWithToken} />
            <Route exact={true} path="/reset-success" component={MobileResetSuccess} />
            <Route exact={true} path="/impersonate/:token" component={Impersonate} />
            <Route exact={true} path="/about-us" component={AboutUs} />
            <Route exact path="/invite/:inviteToken" component={MobileInviteAccept} />
            <Route exact={true} path="/" component={MobileLogin} />
            <Route exact={true} path="/device-registration-qr" component={RegisterNewDeviceQR} />
            <PrivateRoute
              exact
              path="/a/:id"
              render={(routeProps: any) => {
                const id = routeProps?.match?.params?.id;
                return <Redirect to={`/alerts/${id}`} />;
              }}
            />
            <PrivateRoute pageName="Dashboard" exact path="/dashboard"><MobileDashboard /></PrivateRoute>
            {(displayFlags.showSettingsUnitAppControls && displayFlags.enableSettingsUnitAppControls) &&
              <PrivateRoute pageName="App Control Settings" exact path="/settings/unit"> <MobileUnitControlRestrict /></PrivateRoute>
            }
            <PrivateRoute exact path="/management/unit"><MobileUnitManagement /></PrivateRoute>
            {(displayFlags.showSettingsWRCRestrictions && displayFlags.enableSettingsWRCRestrictions) &&
              <PrivateRoute pageName="WRC Settings" exact path="/settings/restricts"><MobileWRCUnitSettings /></PrivateRoute>
            }
            {(displayFlags.showUserManagementUsers && displayFlags.enableUserManagementUsers) &&
              <PrivateRoute
                exact
                path="/users"
              >
                <MobileUserMgmt />
              </PrivateRoute>}
            {(displayFlags.showAlertLog && displayFlags.enableAlertLog) &&
              <PrivateRoute pageName="Alert Log" exact path="/alerts/:notificaitonShortId?"><MobileAlerts /></PrivateRoute>
            }
            {(displayFlags.showAuditLog && displayFlags.enableAuditLog) &&
              <PrivateRoute pageName="Activity Audit" exact path="/audits"><MobileAudits /></PrivateRoute>
            }
            {(displayFlags.showControl && displayFlags.enableControl) &&
              <PrivateRoute pageName="Remote Control" exact path="/control"><MobileControl /></PrivateRoute>
            }
            {(displayFlags.showControl && displayFlags.enableControl) &&
              <PrivateRoute exact path="/unit/:unitId/schedules"><MobileSchedulesList /></PrivateRoute>
            }
            {(displayFlags.showControl && displayFlags.enableControl) &&
              <PrivateRoute exact path="/group/:groupId/schedules"><MobileSchedulesList /></PrivateRoute>
            }
            {(displayFlags.showUnitStatistics && displayFlags.enableUnitStatistics) &&
              <PrivateRoute pageName="Unit Statistics" exact path="/unit-statistics/:unitId"><MobileUnitStatistics /></PrivateRoute>
            }
            {(displayFlags.showUnitStatistics && displayFlags.enableUnitStatistics) &&
              <PrivateRoute pageName="Unit Statistics" exact path="/unit-statistics"><MobileUnitStatistics /></PrivateRoute>
            }
            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute exact path="/unit-diagnostics/:unitId"><MobileUnitDiagnostics /></PrivateRoute>
            }
            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute exact path="/unit-diagnostics"><MobileUnitDiagnostics /></PrivateRoute>
            }
            {displayFlags.showSettingsGroups && displayFlags.enableSettingsGroups &&
              <PrivateRoute pageName="Control Settings Groups" exact path="/settings/groups">
                <GroupMgmt />
              </PrivateRoute>}
            {displayFlags.showSettingsGroups && displayFlags.enableSettingsGroups &&
              <PrivateRoute exact path="/settings/groups/addEdit/:groupId?">
                <GroupAddEdit />
              </PrivateRoute>}
            {displayFlags.showSettingsGroups && displayFlags.enableSettingsGroups &&
              <PrivateRoute exact path="/sites/:siteId/groups">
                <GroupAddEdit />
              </PrivateRoute>}
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute pageName="Site Management" exact path="/site-management"><MobileSiteManagement /></PrivateRoute>
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute pageName="Site Management" exact={true} path="/site-management/customer/:customerId/add-site" component={AddEditSite} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/site/:siteId" component={AddEditSite} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute pageName="Site Management" exact={true} path="/site-management/:deviceId/add-edit-system/:systemId?" component={AddEditSystem} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/device-registration/:siteId/user-selection/:customerId" component={RegisterNewDevice} />
            }
            {(displayFlags.showRegisterDevice && displayFlags.enableRegisterDevice) &&
              <PrivateRoute exact={true} path="/device-registration" component={RegisterNewDevice} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/device/:deviceId/system-detection" component={SystemDetection} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/device-registration/device/:deviceId/system-detection" component={SystemDetection} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/device-registration/device/:deviceId/edit-detected-systems" component={EditDetectedSystems} />
            }
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute exact={true} path="/device/:deviceId/edit-detected-systems" component={EditDetectedSystems} />
            }

            <PrivateRoute exact={true} path="/system/:systemId/schedules" component={MobileSchedulesList} />
            <PrivateRoute exact={true} path="/sensors/:sensorId/schedules" component={MobileSchedulesList} />

            <Redirect to="/" />
          </Switch>
        </Router>
        {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
        {isUsernameNotEmail && (<UserIdentifierChangeRequired />)}
      </div>
    </MuiThemeProvider>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Loader showLoader={isLoaderShowing} />

        <Router>
          <Switch>
            <Route exact={true} path="/forgot-password/" component={ForgotPassword} />
            <Route exact={true} path="/reset-password" component={ResetPassword} />
            <Route exact={true} path="/reset-password/:token" component={ResetPasswordWithToken} />
            <Route exact={true} path="/reset-success" component={ResetSuccess} />
            <Route exact={true} path="/impersonate/:token" component={Impersonate} />
            <Route exact path="/invite/:inviteToken" component={AcceptInvite} />

            <Route exact={true} path="/" component={Login} />
            <PrivateRoute
              exact
              path="/a/:id"
              render={(routeProps: any) => {
                const id = routeProps?.match?.params?.id;
                return <Redirect to={`/alerts/${id}`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              pageName="Dashboard"
            >
              {displayFlags.enableDashboard ? <Dashboard /> : displayFlags.enableControl ? <Redirect to="/control" /> : <Redirect to="/site-management" />}
            </PrivateRoute>
            {(displayFlags.showControl && displayFlags.enableControl) &&
              <PrivateRoute pageName="Remote Control" exact path="/control">
                <Control unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}

            {(displayFlags.showScriptsProcedures && displayFlags.enableScriptsProcedures) &&
              <PrivateRoute pageName="Procuders" exact path="/commissioning-prodecuders">
                <CommissioningList />
              </PrivateRoute>}
            {(displayFlags.showScriptsLogs && displayFlags.enableScriptsLogs) &&
              <PrivateRoute pageName="Test Procedures & Logs" exact path="/commissioning-logs">
                <CommissioningLogs />
              </PrivateRoute>}

            {(displayFlags.showSystemDiagnostics && displayFlags.enableSystemDiagnostics) &&
              <PrivateRoute
                exact
                path="/system-diagnostics/:systemId?"
                pageName="System Diagnostics"
              >
                <SystemDiagnostics />
              </PrivateRoute>}

            {(displayFlags.showUnitStatistics && displayFlags.enableUnitStatistics) &&
              <PrivateRoute
                exact
                path="/unit-statistics/:unitId"
                pageName="Unit Statistics"
              >
                <UnitStatistics />
              </PrivateRoute>}
            {(displayFlags.showUnitStatistics && displayFlags.enableUnitStatistics) &&
              <PrivateRoute
                exact
                path="/unit-statistics/"
                pageName="Unit Statistics"
              >
                <UnitStatistics />
              </PrivateRoute>}

            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute
                exact
                path="/unit-stats/:unitId"
                pageName="Unit Diagnostics"
              >
                <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}

            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute pageName="Unit Diagnostics" exact path="/unit-stats/">
                <DashboardUnit />
              </PrivateRoute>}

            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute pageName="Unit Diagnostics" exact path="/unit-stats/site/:siteId/power/:powerId?" >
                <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}

            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute pageName="Unit Diagnostics" exact path="/unit-stats/site/:siteId/sensor/:sensorId?" >
                <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}
            {(displayFlags.showUnitDiagnostics && displayFlags.enableUnitDiagnostics) &&
              <PrivateRoute pageName="Unit Diagnostics" exact path="/unit-stats/site/:siteId/sensorGroup/:sensorGroupId?" >
                <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}
            {/* {(displayFlags.showAnomalies && displayFlags.enableAdvancedSearch !== false) &&
              <PrivateRoute exact path="/advanced-unit-search">
                <TrapsListHistory />
              </PrivateRoute>}
            {(displayFlags.showAnomalies && displayFlags.enableAdvancedSearchLogs !== false) &&
              <PrivateRoute exact path="/advanced-unit-search-logs">
                <TrapsHistoryLogs />
              </PrivateRoute>} */}
            {enableBacnet && (
              <PrivateRoute exact path="/automation/bacnet-detection" >
                <BacnetDetection />
              </PrivateRoute>
            )}
            {enableBacnet && (
              <PrivateRoute exact path="/automation/bacnet-detection/site/:siteId/bacnet-unit/:bacnetUnitId" >
                <BacNetMapping />
              </PrivateRoute>
            )}
            {(displayFlags.showPowerDistributionUsageAnalysis && displayFlags.enablePowerDistributionUsageAnalysis) &&
              <PrivateRoute pageName="PPD Usage Analysis" exact path="/tenantReports">
                <TenantReports />
              </PrivateRoute>}

            <PrivateRoute exact path="/estimatedPowerUsage">
              <EstimatedPowerUsage />
            </PrivateRoute>

            {(displayFlags.showPowerDistributionZonesSetting && displayFlags.enablePowerDistributionZonesSetting) &&
              <PrivateRoute pageName="PPD Tenants Settings" exact path="/PowerDistribution">
                <PowerDistribution />
              </PrivateRoute>}

            {(displayFlags.showPowerDistributionReportScheduler && displayFlags.enablePowerDistributionReportScheduler) &&
              <PrivateRoute pageName="PPD Report Scheduler" exact path="/scheduler">
                <ReportsScheduler scheduledReportType="ppd" />
              </PrivateRoute>}

            {(displayFlags.showReportsScheduler && displayFlags.enableReportsScheduler) &&
              <PrivateRoute pageName="Site Report Scheduler" exact path="/site-reports-schedulers">
                <ReportsScheduler scheduledReportType="dataReport" />
              </PrivateRoute>}

            {(displayFlags.showReports && displayFlags.enableReports) &&
              <PrivateRoute pageName="Reports" exact path="/site-reports-live/:siteId?">
                <SiteReportsScreen />
              </PrivateRoute>}

            {(displayFlags.showReports && displayFlags.enableReports) &&
              <PrivateRoute pageName="Advanced Reports" exact path="/sigma-reports">
                <SigmaReports />
              </PrivateRoute>}

            {/* {(displayFlags.showReports && displayFlags.enableReports) &&
              <PrivateRoute exact path="/site-reports/:siteId?">
                <SiteReportsScreen />
              </PrivateRoute>} */}

            {(displayFlags.showTrapTemplates && displayFlags.enableTrapTemplates) &&
              <PrivateRoute exact path="/anomaly-templates-settings">
                <AnomalyTemplatesList />
              </PrivateRoute>}
            {(displayFlags.showAutomationLogic && displayFlags.enableAutomationLogic) &&
              <PrivateRoute pageName="Interlocks" exact path="/automation-logic">
                <AutomationLogic />
              </PrivateRoute>}
            {(displayFlags.showSiteManagement && displayFlags.enableSiteManagement) &&
              <PrivateRoute
                exact
                path="/site-management"
                pageName="Site Management"
              >
                <SiteManagement unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
              </PrivateRoute>}

            {(displayFlags.showUserManagementUsers && displayFlags.enableUserManagementUsers) &&
              <PrivateRoute
                exact
                path="/user"
                pageName="User Management"
              >
                <UserList />
              </PrivateRoute>}

            {(displayFlags.showSettingsNotifications && displayFlags.enableSettingsNotifications) &&
              <PrivateRoute
                exact
                path="/settings/alerts"
                pageName="Notification Settings"
              >
                <AlertSettings2 />
              </PrivateRoute>}
            {/* {(displayFlags.showAutomationLogic && displayFlags.enableAutomationLogic) &&
              <PrivateRoute
                exact
                path="/automatic/scheduling"
              >
                <AutomaticScheduling />
              </PrivateRoute>} */}
            {(displayFlags.showAutomaticChangeover && displayFlags.enableAutomaticChangeover) &&
              <PrivateRoute
                exact
                path="/automatic/changeover"
                pageName="Changeover"
              >
                <AutomaticChangeover />
              </PrivateRoute>}

            {false && <PrivateRoute
              exact
              path="/settings/reports"
            >
              <Reports theme={theme} />
            </PrivateRoute>}

            {(displayFlags.showAnomalies && (displayFlags.enableAnomalies || displayFlags.enableOperationalAnomalies)) &&
              <PrivateRoute pageName="Anomalies" exact path="/anomalies-settings">
                <TrapsList />
              </PrivateRoute>}
            {(displayFlags.showSmartAlerts && displayFlags.enableSmartAlerts) &&
              <PrivateRoute pageName="Smart Rules" exact path="/smart-rules">
                <SmartRules />
              </PrivateRoute>
            }

            {(displayFlags.showSmartAlerts && displayFlags.enableSmartAlerts) &&
              <PrivateRoute pageName="Smart Rules" exact path="/smart-rules/site/:ruleSiteId/rule/:ruleId?">
                <SmartRules />
              </PrivateRoute>
            }

            {(displayFlags.showAlertLog && displayFlags.enableAlertLog) &&
              <PrivateRoute pageName="Alert Log" exact path="/alerts/:notificaitonShortId?">
                <Alerts />
              </PrivateRoute>}

            {//(displayFlags.enableUnitUsagePattern) &&
              <PrivateRoute exact path="/unit-usage/:unitId?">
                <HeatMap />
              </PrivateRoute>}

            {(displayFlags.showAuditLog && displayFlags.enableAuditLog) &&
              <PrivateRoute pageName="Activity Audit" exact path="/audits">
                <AuditsPage />
              </PrivateRoute>}

            {false && <PrivateRoute exact path="/edit-units">
              <UnitsEdits />
            </PrivateRoute>}

            {(displayFlags.showSettingsUnitAppControls && displayFlags.enableSettingsUnitAppControls) &&
              <PrivateRoute pageName="App Control Settings" exact path="/settings/unit">
                <UnitSetting />
              </PrivateRoute>}

            {(displayFlags.showSettingsWRCRestrictions && displayFlags.enableSettingsWRCRestrictions) &&
              <PrivateRoute pageName="WRC Settings" exact path="/settings/restricts">
                <Settings />
              </PrivateRoute>
            }

            {displayFlags.showSettingsGroups && displayFlags.enableSettingsGroups &&
              <PrivateRoute pageName="Control Settings Groups" exact path="/settings/groups">
                <Groups />
              </PrivateRoute>}
            {displayFlags.showAwayMode && displayFlags.enableAwayMode && <PrivateRoute pageName="Setback Settings" exact path="/settings/setback" component={Setback} />}
            {true &&
              <PrivateRoute exact path="/floor-plan/:site?">
                <FloorPlan />
              </PrivateRoute>}

            {(displayFlags.enablePowerDistributionZonesSetting) &&
              <PrivateRoute pageName="PPD Power Meter Settings" exact={true} path="/power-meters-settings" component={PPDSettings} />
            }
            {(enableErrorPage) &&
              <PrivateRoute exact={true} path="/error" component={() => null} />
            }

            <Redirect to="/" />
          </Switch>
        </Router>
        {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
        {isUsernameNotEmail && (<UserIdentifierChangeRequired />)}
      </div>
    </MuiThemeProvider>
  );
}
